import { template as template_d9c2656a354b437b9bfa5ad9c2b2e8e8 } from "@ember/template-compiler";
const FKLabel = template_d9c2656a354b437b9bfa5ad9c2b2e8e8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
