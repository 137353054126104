import { template as template_cbfc4d14fa674a2d8615405509d9f25a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_cbfc4d14fa674a2d8615405509d9f25a(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
