import { template as template_77be3535c55c449da13e60986abbff3c } from "@ember/template-compiler";
const FKControlMenuContainer = template_77be3535c55c449da13e60986abbff3c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
